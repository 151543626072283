const gtmEvent = 'recruitmentPageButtonClick';
const viewAllJobs = 'View all jobs';
const viewVacancies = 'View vacancies';
const promoBanner = 'Promo Banner';
const header = 'Header';
const heroBanner = 'Hero Banner';
const workAtBasicFit = 'Work at BasicFit';
const ourJourney = 'Our Journey';
const perfectFit = 'Perfect Fit';
const orangeFamily = 'Orange Family';
const startQuiz = 'Start Quiz';
const meetOrangeFamily = 'Meet the Orange Family';


const RecruitmentPage = (() => {
    return {
        init() {
            // Recruitment GTM events
            const $body = $('body');

            $body.on('click', '.banner-link', (e) => {
                $(document).trigger(gtmEvent, [promoBanner, viewAllJobs]);
            });

            $body.on('click', '.hero-banner__join', (e) => {
                $(document).trigger(gtmEvent, [heroBanner, viewAllJobs]);
            });

            $body.on('click', '.work-at-container__block-tile-content-container-link', (e) => {
                const text = $($(e.currentTarget)).closest('.work-at-container__block-tile-content-container').find('.work-at-container__block-tile-content-container-title').text()
                    .trim();
                $(document).trigger(gtmEvent, [workAtBasicFit, `${viewVacancies} - ${text}`]);
            });

            $body.on('click', '.work-at-container__link', (e) => {
                $(document).trigger(gtmEvent, [workAtBasicFit, viewAllJobs]);
            });

            $body.on('click', '.promo-banner__btn', (e) => {
                $(document).trigger(gtmEvent, [perfectFit, startQuiz]);
            });

            $body.on('click', '.journey-section__item-btn', (e) => {
                const text = $(e.currentTarget).text().trim();
                $(document).trigger(gtmEvent, [ourJourney, text]);
            });

            $body.on('click', '.orange-family-section__link', (e) => {
                $(document).trigger(gtmEvent, [orangeFamily, meetOrangeFamily]);
            });

            $body.on('click', '.js-header-menu-item', (e) => {
                const text = $(e.currentTarget).text().trim();
                $(document).trigger(gtmEvent, [header, text]);
            });
        },
    };
})();

export default (() => {
    $(document).ready(RecruitmentPage.init);
    $(window).on('resize', RecruitmentPage.init);
})();
